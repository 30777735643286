import React from 'react'
import Helmet from 'react-helmet'

const Fonts = () => (
  <Helmet>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
    <link
      href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap"
      rel="stylesheet"
    />
  </Helmet>
)

export default Fonts
