const theme = {
  color: {
    muted: '#818a97',
    text: '#5b6677',
    textDark: '#474f5b',
    border: '#dde4e9',
    accent: {
      blue: '#0054FE',
      pink: '#FB3673',
      purple: '#742AFD',
      orange: '#fa5f2e',
      lightBlue: '#00A0FE',
    },
  },
  borderRadius: '0.5rem',
}

export default theme
