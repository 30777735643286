import { createGlobalStyle } from 'styled-components'
import theme from 'styles/theme'

export default createGlobalStyle`
  html {
    font-size: 62.5%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    -webkit-touch-callout: none;
    font-family: 'Roboto Slab', sans-serif;
    font-display: swap;
    font-size: 1.8rem;
    color: ${theme.color.text};
    margin: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Roboto Slab', sans-serif;
    color: ${theme.color.textDark};
  }

  h1 {
    font-size: 3.6rem;
    margin: 3rem 0 0;
  }

  h2 {
    font-size: 2.6rem;
    margin: 1.5em 0;
  }

  h3 {
    font-size: 2.1rem;
    margin: 1.5em 0;
  }

  p,
  li {
    font-weight: 300;
    line-height: 1.8;
  }

  td {
    border-bottom: none;
  }

  hr {
    background: ${theme.color.border};
    margin: 3rem 0;
    border: none;
    height: 0.1rem;
  }

  a {
    color: ${theme.color.accent.blue};
    text-decoration: none;
  }

  blockquote {
    padding: 0.1rem 3rem;
    border-left: 0.5rem solid;
    margin: 0;
    font-style: italic;
  }

  code {
    font-size: 2rem;
    background: #f7ebff;
    padding: 0.3rem 0.5rem;
    border-radius: 0.5rem;
  }
`
